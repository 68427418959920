import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Em } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Технічна Підтримка 360
			</title>
			<meta name={"description"} content={"Швидкі рішення, надійні результати"} />
			<meta property={"og:title"} content={"Технічна Підтримка 360"} />
			<meta property={"og:description"} content={"Швидкі рішення, надійні результати"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/669925669710450018a1672b/images/mobile-device-repair.webp?v=2024-07-18T14:35:15.809Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/669925669710450018a1672b/images/mobile-device-repair.webp?v=2024-07-18T14:35:15.809Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/669925669710450018a1672b/images/mobile-device-repair.webp?v=2024-07-18T14:35:15.809Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/669925669710450018a1672b/images/mobile-device-repair.webp?v=2024-07-18T14:35:15.809Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/669925669710450018a1672b/images/mobile-device-repair.webp?v=2024-07-18T14:35:15.809Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/669925669710450018a1672b/images/mobile-device-repair.webp?v=2024-07-18T14:35:15.809Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/669925669710450018a1672b/images/mobile-device-repair.webp?v=2024-07-18T14:35:15.809Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Header>
		<Components.Header1>
			<Override slot="text" />
			<Override slot="link" />
		</Components.Header1>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="60%"
				md-width="100%"
			>
				<Text
					font="--headline1"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
				>
					Ласкаво просимо до Технічна Підтримка 360
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="100px 0px 0px 0px"
					color="--light"
					font="--lead"
				>
					У Технічна Підтримка 360 PC Repair ми знаємо, наскільки важлива ваша технологія для повсякденної роботи. Незалежно від того, чи боретеся ви з вторгненням шкідливого програмного забезпечення, чи страждаєте від апаратної несправності, наша команда готова надати вам швидкі та надійні рішення, які допоможуть швидко повернутися до нормальної роботи. Дякуємо, що обрали нас - ми є тим місцем, де ваші проблеми з комп'ютером зустрічають своє рішення.
				</Text>
			</Box>
		</Section>
		<Section padding="60px 0 60px 0" border-color="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" sm-flex-wrap="wrap" />
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				display="flex"
				sm-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					min-width="600px"
					src="https://uploads.quarkly.io/669925669710450018a1672b/images/repairman-uses-magnifier-tweezers-repair-damaged-smartphone-close-up-photo-disassembled-smartphone-scaled.jpg?v=2024-07-18T14:35:15.810Z"
					max-height="600px"
					object-fit="cover"
					lg-max-height="350px"
					lg-max-width="100%"
					width="100%"
					lg-width="100%"
					lg-min-width="350px"
					srcSet="https://smartuploads.quarkly.io/669925669710450018a1672b/images/repairman-uses-magnifier-tweezers-repair-damaged-smartphone-close-up-photo-disassembled-smartphone-scaled.jpg?v=2024-07-18T14%3A35%3A15.810Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/repairman-uses-magnifier-tweezers-repair-damaged-smartphone-close-up-photo-disassembled-smartphone-scaled.jpg?v=2024-07-18T14%3A35%3A15.810Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/repairman-uses-magnifier-tweezers-repair-damaged-smartphone-close-up-photo-disassembled-smartphone-scaled.jpg?v=2024-07-18T14%3A35%3A15.810Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/repairman-uses-magnifier-tweezers-repair-damaged-smartphone-close-up-photo-disassembled-smartphone-scaled.jpg?v=2024-07-18T14%3A35%3A15.810Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/repairman-uses-magnifier-tweezers-repair-damaged-smartphone-close-up-photo-disassembled-smartphone-scaled.jpg?v=2024-07-18T14%3A35%3A15.810Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/repairman-uses-magnifier-tweezers-repair-damaged-smartphone-close-up-photo-disassembled-smartphone-scaled.jpg?v=2024-07-18T14%3A35%3A15.810Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/repairman-uses-magnifier-tweezers-repair-damaged-smartphone-close-up-photo-disassembled-smartphone-scaled.jpg?v=2024-07-18T14%3A35%3A15.810Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				width="50%"
				background="--color-green"
				display="flex"
				flex-direction="column"
				lg-padding="24px 24px 24px 24px"
				empty-border-color="LightGray"
				align-items="center"
				justify-content="center"
				empty-min-width="64px"
				empty-border-style="solid"
				padding="86px 86px 86px 86px"
				sm-width="100%"
			>
				<Text
					margin="0px 0px 32px 0px"
					color="--dark"
					font="--headline4"
					lg-font="700 22px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					lg-margin="0px 0px 16px 0px"
				>
					Про нас
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
				>
					Технічна Підтримка 360 PC Repair спеціалізується на наданні першокласних послуг з ремонту комп'ютерів. Наша команда сертифікованих техніків володіє знаннями та інструментами, необхідними для діагностики та усунення всіх комп'ютерних проблем. Від усунення несправностей програмного забезпечення до ремонту апаратного забезпечення, ми забезпечимо роботу вашого комп'ютера так само безперебійно, як і в перший день.
				</Text>
			</Box>
		</Section>
		<Section padding="120px 0 120px 0" md-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
			>
				<Text
					font="--headline2"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					margin="0px 0px 32px 0px"
					color="--light"
				>
					Наше зобов'язання
				</Text>
				<Text
					color="--light"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 16px 0px"
					margin="0px 0px 0px 0px"
				>
					Ми не тільки вирішимо ваші проблеми, але й подбаємо про те, щоб ви зрозуміли, що пішло не так і як запобігти виникненню проблем у майбутньому. Наш прозорий процес і чесні поради покликані продовжити термін служби вашого ПК, що робить Технічна Підтримка 360 партнером, якому ви можете довіряти.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/669925669710450018a1672b/images/mobile-device-repair.webp?v=2024-07-18T14:35:15.809Z"
					width="100%"
					max-height="450px"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					srcSet="https://smartuploads.quarkly.io/669925669710450018a1672b/images/mobile-device-repair.webp?v=2024-07-18T14%3A35%3A15.809Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/mobile-device-repair.webp?v=2024-07-18T14%3A35%3A15.809Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/mobile-device-repair.webp?v=2024-07-18T14%3A35%3A15.809Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/mobile-device-repair.webp?v=2024-07-18T14%3A35%3A15.809Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/mobile-device-repair.webp?v=2024-07-18T14%3A35%3A15.809Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/mobile-device-repair.webp?v=2024-07-18T14%3A35%3A15.809Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/mobile-device-repair.webp?v=2024-07-18T14%3A35%3A15.809Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="60px 0 40px 0" lg-padding="50px 0 25px 0" md-padding="30px 0 25px 0">
			<Override slot="SectionContent" align-items="flex-end" />
			<Text
				margin="0px 0px 16px 0px"
				color="--light"
				font="--headline2"
				lg-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
				md-font="normal 500 22px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
				width="100%"
			>
				Чому варто обрати Технічна Підтримка 360?
			</Text>
			<Text
				color="--light"
				font="--headline2"
				lg-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
				width="100%"
				margin="0px 0px 0px 0px"
			>
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				/>
			</Text>
			<Box
				empty-min-height="64px"
				empty-border-color="LightGray"
				width="50%"
				empty-min-width="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				margin="40px 0px 0px 0px"
				lg-width="70%"
				md-width="100%"
			>
				<Text margin="0px 0px 16px 0px" color="--light" font="--base" text-align="left">
					Досвідчені технічні спеціалісти: Завдяки технічному досвіду нашої команди ми можемо вирішити будь-яку проблему з комп'ютером.{"\n"}
					<br />
					<br />
					{"\n"}Швидке обслуговування: Ми знаємо, що ви не можете чекати, тому ми надаємо пріоритет швидкому виконанню замовлень.
				</Text>
				<Box display="grid" grid-template-columns="1fr 1fr" grid-gap="0px 40px">
					<Box>
						<Image
							src="https://uploads.quarkly.io/669925669710450018a1672b/images/bnrr_ifix.webp?v=2024-07-18T14:35:15.791Z"
							max-height="120px"
							width="100%"
							object-fit="contain"
							lg-height="120px"
							srcSet="https://smartuploads.quarkly.io/669925669710450018a1672b/images/bnrr_ifix.webp?v=2024-07-18T14%3A35%3A15.791Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/bnrr_ifix.webp?v=2024-07-18T14%3A35%3A15.791Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/bnrr_ifix.webp?v=2024-07-18T14%3A35%3A15.791Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/bnrr_ifix.webp?v=2024-07-18T14%3A35%3A15.791Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/bnrr_ifix.webp?v=2024-07-18T14%3A35%3A15.791Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/bnrr_ifix.webp?v=2024-07-18T14%3A35%3A15.791Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/bnrr_ifix.webp?v=2024-07-18T14%3A35%3A15.791Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Text color="--green" lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif" margin="8px 0px 0px 0px" font="--lead">
							Прозорі ціни
						</Text>
					</Box>
					<Box>
						<Image
							lg-height="120px"
							src="https://uploads.quarkly.io/669925669710450018a1672b/images/Device-Repair.png?v=2024-07-18T14:35:15.798Z"
							max-height="120px"
							width="100%"
							object-fit="cover"
							srcSet="https://smartuploads.quarkly.io/669925669710450018a1672b/images/Device-Repair.png?v=2024-07-18T14%3A35%3A15.798Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/Device-Repair.png?v=2024-07-18T14%3A35%3A15.798Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/Device-Repair.png?v=2024-07-18T14%3A35%3A15.798Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/Device-Repair.png?v=2024-07-18T14%3A35%3A15.798Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/Device-Repair.png?v=2024-07-18T14%3A35%3A15.798Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/Device-Repair.png?v=2024-07-18T14%3A35%3A15.798Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/Device-Repair.png?v=2024-07-18T14%3A35%3A15.798Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Text margin="8px 0px 0px 0px" font="--lead" color="--green" lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif">
							Постійний ремонт
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link1" />
			<Override slot="text4" />
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="text2" />
			<Override slot="linkBox" />
			<Override slot="link" />
			<Override slot="box" />
			<Override slot="text3" />
			<Override slot="text5" />
			<Override slot="text6" />
			<Override slot="text7" />
			<Override slot="text8" />
			<Override slot="text9" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});